/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
| 
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

jQuery(document).ready(function() {

  // Evolving function for WCAG guidelines
  v8_wcag(); 

  // Detect Internet Explorer
  detect_IE();

  // Detect Native Android Browser
  detect_native_android_browser();

  // Apple Device Detect
  apple_device_detect();

  // Enable AOS for use out of the box
  AOS.init({
    disable: 'mobile'
  });

  // Carousel Function, homepage services and single project gallery image
  var pages_need_owl = [
    '.gallery'
    ];
  
    for (var i = 0; i < pages_need_owl.length; i++) {
      if (jQuery(pages_need_owl[i]).length > 0) {
        owl();
      }
    }



  // Enable PLYR for use
  // const player = new Plyr('#player', {
  //   /* options */
  //   ratio: '1:1'
  // });

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  isotope_masonry();

  // TablePress Cleanup
  // tablepress();
  
  tableacf();

  // Event Target Testing
  jQuery(document).click(function(event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function(event) {        

  });

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

  // Main content container and mega menu top offset for fixed navigation
  // setInterval(function(){

  //   var header_height = (jQuery('header.header').height()); 
  //   jQuery("#content").attr('style','margin-top:'+header_height+'px !important;');
  //   // jQuery("#mega-menu .dropdown-pane").attr('style','top:'+header_height+'px !important;');

  // },1);

  // Contact pass data to subject field

  jQuery('.list_departments li a').click(function(event) {
    var subject = jQuery(this).clone().attr('subject');
    console.log(subject);
    jQuery("#input_1_8").val(subject);
  });

  // Branch select

  jQuery('#locations-filter').on('change', function () {
    jQuery('body, html').animate({ scrollTop: jQuery('#' + jQuery(this).val()).position().top });
  });
    

  // Cookies Hide Alert and Normal announcement 

  // Set Cookies

  jQuery('section.announcement.urgent button.close-button').click(function(event) {

    SetCookieAndHideUrgent();

  });

  jQuery('section.announcement.normal button.close-button').click(function(event) {

    SetCookieAndHideNormalAnn();

  });

  // Hide Announcements
  if(getCookie("HideUrgent") == "true")
  {
    jQuery("section.announcement.urgent").css('display','none');
  }

  if(getCookie("HideNormal") == "true")
  {
    jQuery("section.announcement.normal").css('display','none');
  }

  onElementHeightChange(document.body, function(){
    AOS.refresh({
    disable: 'mobile'
    });
  });

  jQuery( window ).resize(function() {
    AOS.refresh({
    disable: 'mobile'
    });

  });

  jQuery('[href="#main"]').click(function(e){
    jQuery('html,body').animate({
      scrollTop: jQuery("#main").offset().top - 150
    },1200);
    e.preventDefault();
    e.stopPropagation();
  });

  // Mortgage calculator
  jQuery('#payment-schedule-submit').click(function(e){
    calculate_monthly_payment();
    e.preventDefault();
    e.stopPropagation();
  });

}); // DOM

function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight
  var newHeight;
  
  (function run() {
      newHeight = elm.clientHeight;      
      if (lastHeight !== newHeight) callback();
      lastHeight = newHeight;

      if (elm.onElementHeightChangeTimer) {
        clearTimeout(elm.onElementHeightChangeTimer); 
      }

      elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}
/*
|==========================================================================
| Isotope Masonry Grid
|==========================================================================
| 
| This function activates a grid based masonry layout that can be
| customized per project using the Isotope plugin.
|
|==========================================================================
| @reference: https://isotope.metafizzy.co/
|
*/


// Activates grid based masonry layout
function isotope_masonry() {

  // init Isotope
  var $grid = jQuery('.grid').isotope({
    itemSelector: '.grid-item',
    percentPosition: true,

  });
  
  // bind filter on select change
  jQuery('#prodotti-filter').on('change', function () {
    // get filter value from option value
    var filterValue = this.value;

    $grid.isotope({ filter: filterValue });
  });

  
}
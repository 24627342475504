/*
|==========================================================================
| Initialize Foundation
|==========================================================================
| 
| This initializes Foundation.
|
|==========================================================================
|
*/

// Off Canvas Duplicate ID Cleanup
jQuery('.off-canvas .menu-item').each(function(i){
    var this_element = jQuery(this);
    var this_element_id = this_element.attr('id');
    this_element.attr('id',this_element_id+'-off-canvas');
});

// Foundation
jQuery(document).foundation();

// Mega Menu Connection
// Important for Foundation Dropdown Pane Label Accessibility Correction
jQuery('#mega-menu > .dropdown-pane').each(function(i){
    var this_element = jQuery(this);
    var aria_labelledby = this_element.data('menu-item-id');
    this_element.attr('aria-labelledby',aria_labelledby); 
});
function owl() {

    // Enable OWL for gallery
    jQuery('.gallery .owl-carousel-gallery').owlCarousel({
          items:1,
          dots: false,
          loop: true,
          center:true,
          nav: true,
          margin:0,
          autoplay:false,
          autoplayTimeout:10000,
          autoplayHoverPause:true
    });


}